require('./bootstrap');
import Masonry from 'masonry-layout';
import InfiniteScroll from 'infinite-scroll';
import imagesLoaded from 'imagesloaded';
import Swal from 'sweetalert2';
import i18next from 'i18next';
import slick from 'slick-carousel';
import LazyLoad from "vanilla-lazyload";
import ClipboardJS from "clipboard";
import WOW from "wow.js"
import iFrameResize from 'iframe-resizer'
import Filterizr from 'filterizr'
import StickySidebar from 'sticky-sidebar'

const myLazyLoad = new LazyLoad();

InfiniteScroll.imagesLoaded = imagesLoaded;

const default_lang = document.documentElement.lang.substr(0, 2);

const i18n = i18next.createInstance({
    lng: default_lang,
    fallbackLng: default_lang,
    nsSeparator: '.'
}, (err, t) => {

});
$.each(vuei18nLocales, function(lng, locale) {
    $.each(locale, function(key, trans) {
        i18n.addResources(lng, key, trans);
    });
});
window.locale = i18n;
window.Filterizr = Filterizr;

const Quizier = function() {
    const sweetDelete = function() {
            $(".warning-delete").on("click", function(event) {
                event.preventDefault();
                Swal.fire({
                    title: i18n.t('i18n.areYouSureToDelete'),
                    text: i18n.t('i18n.canNotRecover'),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: i18n.t('i18n.yesDeleteIt'),
                    cancelButtonText: i18n.t('i18n.noCancel'),
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        if ($(this).hasClass('frm-submit')) {
                            $(this).parents('form:first').submit();
                        }
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            i18n.t('i18n.cancelled'),
                            i18n.t('i18n.operationCancelled'),
                            'error'
                        )
                    }
                });
            });
        },
        checkAll = function() {
            const $check_all = $('.checkbox_all[type=checkbox]');
            if (!$check_all.length) {
                return;
            }

            $check_all.click(function() {
                $('input:checkbox').not(this).prop('checked', this.checked);
            });
        },
        sweetTrash = function() {
            $(".warning-trash").on("click", function(event) {
                event.preventDefault();
                Swal.fire({
                    title: i18n.t('i18n.areYouSure'),
                    text: i18n.t('i18n.sendItemToTrash'),
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonText: i18n.t('i18n.yesTrashIt'),
                    cancelButtonText: i18n.t('i18n.noCancel'),
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        if ($(this).hasClass('frm-submit')) {
                            $(this).parents('form:first').submit();
                        }
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            i18n.t('i18n.cancelled'),
                            i18n.t('i18n.operationCancelled'),
                            'error'
                        )
                    }
                });
            });
        },
        initSlug = function() {
            $(document).on("keyup change", '.slug_title', function() {
                var title = $(this).val();
                var titleUrlString = Quizier.convertToSlug(title);
                $(this).parent().parent().parent().find('.slug').val(titleUrlString);
            });
        },
        noAutoCloseDropdown = function () {
            $(document).on('click', '.options-panel .dropdown-menu', function (e) {
                e.stopPropagation();
            });
        },
        initSocialStream = function() {
            const elems = document.querySelectorAll('.initSocial');
            const $elems = [].slice.call(elems);
            $elems.map((elem) => {
                if (elem.childNodes.length != 0) {
                    if (typeof FB !== 'undefined') {
                        FB.XFBML.parse(elem);
                        elem.classList.remove("initSocial");
                    }
                    if (typeof twttr !== 'undefined') {
                        twttr.widgets.load(elem);
                        elem.classList.remove("initSocial");
                    }
                }
            });

            if (typeof instgrm !== 'undefined') {
                instgrm.Embeds.process();
            }
        },
        resizeiFrame = function() {
            const $allVideos = $("iframe[src*='//player.vimeo.com'], iframe[src*='//www.youtube.com']"),
                $fluidEl = $("#app-modules");
            $allVideos.each(function() {
                $(this).attr('data-aspectRatio', this.height / this.width).removeAttr('height').removeAttr('width');
            });
            $(window).resize(function() {
                var newWidth = $fluidEl.width();
                $allVideos.each(function() {
                    var $el = $(this);
                    $el.width(newWidth).height(newWidth * $el.attr('data-aspectRatio'));
                });
            }).resize();
        },
        fileSelectorInput = function() {

        },
        clipboard = function() {
            var clipboard = new ClipboardJS('.btn-clip', {
                text: function(trigger) {
                    return trigger.getAttribute('aria-label');
                }
            });
            clipboard.on('success', (e) => {
                let old = e.trigger.innerHTML;
                let text = e.trigger.dataset.copied;
                e.trigger.innerHTML = text;
                setTimeout(function() {
                    e.trigger.innerHTML = old;
                }, 2000);
            });
        },
        backToTop = function() {
            $('body').append(
                "<a href='#' class='back-to-top animated faster'>" +
                "<i class='fas fa-chevron-up'></i>" +
                "</a>"
            );

            var offset = 500;
            var duration = 800;
            $(window).scroll(function() {
                if ($(this).scrollTop() > offset) {
                    $('.back-to-top').addClass('fadeInUp');
                } else {
                    $('.back-to-top').removeClass('fadeInUp');
                }
            });

            $('.back-to-top').click(function(e) {
                e.stopPropagation();
                $('body,html').animate({
                    scrollTop: 0
                }, duration);
                return false;
            });
        },
        makeSelect = function() {
            if ($('.select-all').length) {
                $(document).on('click', '.select-all', function() {
                    this.select();
                });
            }
        },
        makeEmbed = function() {
            if ($('.make-embed').length) {
                $(document).on('click', '.make-embed', function(e) {
                    let Url = $(this).data('url') ? $(this).data('url') : ($("#embed-url").attr('href') ? $("#embed-url").attr('href') : false);
                    let Sdk = window.variables.sdk_url;
                    if (Url && Sdk) {
                        const script = "<script>(function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(d.getElementById(id))return;js=d.createElement(s);js.id=id;js.src='" + Sdk + "';fjs.parentNode.insertBefore(js,fjs);}(document,'script','quizier-sdk'));</script>";
                        const html = '<div class="story-embed" data-url="' + Url + '" data-show-info="true"></div>';
                        const template = script + html;
                        $('#embedModal #embed_code').val(template);

                        const imageUrl = $(this).data('cover') ? $(this).data('cover') : (window.postData.embed.cover ? window.postData.embed.cover : false)
                        const title = $(this).data('title') ? $(this).data('title') : (window.postData.embed.title ? window.postData.embed.title : false)
                        if (imageUrl) {
                            $('#embedModal #embedHeader').css('background-image', 'url(' + imageUrl + ')');
                        }
                        if (title) {
                            $('#embedModal .modal-title').html(title);
                        }

                        $('#embedModal').modal({
                            show: true
                        })
                    }
                });
            }
        },
        inIframe = function() {
            if (top != self) {
                $('#embed_page a').attr('target', '_blank');
            } else {
                backToTop();
            }
        },
        sliderInit = function() {
            let $elem = $('.centered-slider');
            let $dir = $elem.attr('dir');

            $elem.slick({
                centerMode: true,
                variableWidth: true,
                adaptiveHeight: true,
                arrows: true,
                rtl: ($dir == 'rtl' ? true : false),
                centerPadding: '250px',
                slidesToShow: 1,
                focusOnSelect: true,
                responsive: [{
                        breakpoint: 960,
                        settings: {
                            centerPadding: '200px',
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            centerPadding: '125px',
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            centerPadding: '10px',
                        }
                    }
                ]
            });
        },
        utilities = function() {
            $('body').tooltip({
                selector: '[data-toggle="tooltip"]'
            });

            $(window).scroll(function() {
                var scroll = $(window).scrollTop();
                if (scroll >= 500) {
                    $("body").addClass("offset-down");
                    $('.scrolling-navbar').addClass('fixed-top');
                } else {
                    $("body").removeClass("offset-down");
                    $('.scrolling-navbar').removeClass('fixed-top');
                }
            });

            $('.navbar-account .dropdown-menu').click(function(e) {
                e.stopPropagation();
            });

            $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
                if (!$(this).next().hasClass('show')) {
                    $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
                }
                var $subMenu = $(this).next(".dropdown-menu");
                $subMenu.toggleClass('show');

                $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
                    $('.dropdown-menu .show').removeClass("show");
                });

                return false;
            });
        },
        postVoting = function () {
            $(document).on('click', '.voting-button', function () {
                const url = window.variables.ranking_url;
                const action = $(this).data();
                if (!$(this).hasClass('user-voted')) {
                    $(this).parent().find('button').removeClass('user-voted')
                    axios.post(url, action).then(res => {
                        if (res.data.reload) {
                            location.reload();
                        }
                        if (res.data.success) {
                            $(this).addClass('user-voted');
                            $(this).parent().find('.voting-score strong').html(res.data.action.counts.scount)
                        } else if (res.data.message) {
                            Quizier.sweetInfo(res.data.message)
                        }
                    }).catch(err => {
                        Quizier.sweetError(err.response.data.message)
                        return false;
                    });
                }
            });
        };
    return {
        init: function() {
            if(!$('body').hasClass('quizier-initialized')) {
                makeSelect()
                clipboard();
                utilities()
                sweetDelete();
                sweetTrash();
                initSlug();
                noAutoCloseDropdown();
                checkAll();
                sliderInit();
                makeEmbed();
                inIframe();
                postVoting();
                this.logoutInit();
                this.setupSocial();
                this.socialShare();
                this.masonaryGrid();
                this.updateLazyload();
                this.sidebarSticky();
            }
        },
        sidebarSticky: function() {
            if ($('section.main').hasClass('sticky-sidebar') && $('#secondary').length > 0) {
                var stickySidebar = new StickySidebar('#secondary', {
                    topSpacing: $('nav.navbar').outerHeight(true) + 10,
                    bottomSpacing: 0,
                    resizeSensor: true,
                    containerSelector: '.main-wrap',
                    innerWrapperSelector: '.widgets-wrap',
                    minWidth: 991
                });
                window.stickySidebar = stickySidebar;
            }
        },
        initSlider: function() {
            let $elem = $('.custom-slider').not('.slick-initialized');
            if ($elem.length > 0) {
                $elem.each(function() {
                    var $this = $(this);
                    let $dir = $this.attr('dir');
                    $($this).slick({
                        variableWidth: false,
                        adaptiveHeight: false,
                        arrows: false,
                        autoplay: false,
                        rows: 0,
                        rtl: ($dir == 'rtl' ? true : false),
                        slidesToShow: 1,
                        focusOnSelect: false,
                    });
                });
            }
        },
        masonaryGrid: function() {
            if ($('.story-grid .story-item').length > 0) {
                var $grid = document.querySelector('.story-grid');
                var $masonry = new Masonry($grid, {
                    itemSelector: '.story-item',
                    columnWidth: '.story-sizer',
                    horizontalOrder: true,
                    percentPosition: true,
                });
                imagesLoaded($grid).on('progress', function() {
                    $masonry.layout();
                });
                this.autoPaging($masonry);
            }
        },
        convertToSlug: function(string) {
            const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįıłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
            const b = 'aaaaaaaaacccddeeeeeeegghiiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
            const p = new RegExp(a.split('').join('|'), 'g')

            return string.toString().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                .replace(/&/g, '-and-') // Replace & with 'and'
                .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                .replace(/\-\-+/g, '-') // Replace multiple - with single -
                .replace(/^-+/, '') // Trim - from start of text
                .replace(/-+$/, '') // Trim - from end of text
        },
        logoutInit: function() {
            $(document).on('click', '.signoutBtn', function(e) {
                e.preventDefault();
                if (FB) {
                    FB.getLoginStatus(function(response) {
                        if (response && response.status === 'connected') {
                            FB.logout();
                        }
                    });
                }
                $('#logout-form').submit();
            });
        },
        get_nav: function() {
            if (variables && variables.get_nav) {
                var $this = this;
                $('#user-nav-wrapper').load(variables.get_nav, function() {
                    // $this.logoutInit();
                });
            }
        },
        autoPaging: function($masonry) {
            if ($('.auto-paging').length) {
                if ($('.auto-paging li:last-child > a').length) {
                    var options = {
                        path: '.auto-paging li:last-child > a',
                        append: '.story-item',
                        outlayer: $masonry,
                        history: false,
                        hideNav: '.auto-paging .pagination',
                        status: '.auto-paging .scroller-status',
                    };
                    var infScroll = new InfiniteScroll('.story-grid', options);

                    infScroll.on('append', function(response, path, items) {
                        Quizier.reinitOnRequest();
                    });
                    infScroll.on('request', function(path) {
                        Quizier.reinitOnRequest();
                    })
                } else {
                    $('.auto-paging').hide();
                }
            }
        },
        reinitOnRequest: function() {
            myLazyLoad.update();
            if (typeof stickySidebar !== 'undefined') {
                stickySidebar.updateSticky();
            }
        },
        updateLazyload: function() {
            Quizier.reinitOnRequest();
            $.ajaxSetup({
                complete: function() {
                    Quizier.updateLazyload();
                }
            });
        },
        initSocial: function() {
            initSocialStream();
            resizeiFrame();
        },
        initFB: function() {
            if (window.FB && window.fb_app_id) {
                FB.init({
                    appId: fb_app_id,
                    status: true,
                    cookie: true,
                    xfbml: true,
                    version: 'v6.0'
                });
            }
        },
        socalizePage: function() {
            $.getScript('https://assets.pinterest.com/js/pinit_main.js');
            $.getScript('https://embed.redditmedia.com/widgets/platform.js');
            $.getScript('https://embedr.flickr.com/assets/client-code.js');
            $.getScript('https://www.instagram.com/embed.js');
            $.getScript('https://platform.twitter.com/widgets.js');
            $.getScript('https://s.imgur.com/min/embed.js');
            Quizier.initSocial();
        },
        socialShare: function() {
            var popupSize = {
                width: 780,
                height: 550
            };

            $(document).on('click', '.social-button', function(e) {
                var verticalPos = Math.floor(($(window).width() - popupSize.width) / 2),
                    horisontalPos = Math.floor(($(window).height() - popupSize.height) / 2);

                var popup = window.open($(this).prop('href'), 'social',
                    'width=' + popupSize.width + ',height=' + popupSize.height +
                    ',left=' + verticalPos + ',top=' + horisontalPos +
                    ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');

                if (popup) {
                    popup.focus();
                    e.preventDefault();
                }
            });
        },
        setupSocial: function(result = false) {
            let Url = $("#canonical-url").length ? $("#canonical-url").attr('href') : window.location.href;
            var title = encodeURIComponent($('.post-title').text());
            if (result) {
                var tempUrl = new URL(Url)
                tempUrl.searchParams.append('result', result.id);
                Url = tempUrl.toString();
                if (result.title) {
                    title = result.title.replace(/<[^>]*>?/gm, '');
                }
            }

            var UrlEncoded = encodeURIComponent(Url);

            $(".is-facebook").attr('href', "https://www.facebook.com/share.php?u=" + UrlEncoded)
            $(".is-twitter").attr('href', "https://twitter.com/intent/tweet?text=" + title + " " + UrlEncoded)
            $(".is-linkedin").attr('href', "https://www.linkedin.com/shareArticle?mini=true&url=" + UrlEncoded + "&title=" + title)
            $(".is-pinterest").attr('href', "https://pinterest.com/pin/create/link/?url=" + UrlEncoded)
            $(".is-reddit").attr('href', "https://www.reddit.com/submit?url=" + UrlEncoded + "&title=" + title)
            $(".is-email").attr('href', "mailto:?body=Take a look at this page I found: " + title + ". You can read it here: " + Url)
        },
        sweetError: function(text, callback, options) {
            const defaults = {
                icon: 'error',
                title: i18n.t('i18n.error'),
                text: text,
                confirmButtonText: i18n.t('i18n.ok'),
                allowOutsideClick: false,
                allowEscapeKey: false
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function(result) {
                typeof callback === 'function' && callback();
            });
        },
        sweetErrors: function(html, callback, options) {
            const defaults = {
                icon: 'error',
                title: i18n.t('i18n.error'),
                html: html,
                confirmButtonText: i18n.t('i18n.ok'),
                allowOutsideClick: false,
                allowEscapeKey: false
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function(result) {
                typeof callback === 'function' && callback();
            });
        },
        sweetInfo: function(text, callback, options) {
            const defaults = {
                icon: 'info',
                title: i18n.t('i18n.information'),
                text: text,
                confirmButtonText: i18n.t('i18n.ok'),
                allowOutsideClick: false,
                allowEscapeKey: false,
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function(result) {
                typeof callback === 'function' && callback();
            });
        },
        sweetSuccess: function(text, callback, options) {
            const defaults = {
                icon: 'success',
                title: i18n.t('i18n.success'),
                text: text,
                timer: 2000,
                confirmButtonText: i18n.t('i18n.ok'),
                allowOutsideClick: false,
                allowEscapeKey: false,
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function(result) {
                typeof callback === 'function' && callback();
            });
        },
        sweetPrompt: function(text, callback, options) {
            const defaults = {
                title: text,
                input: 'text',
                confirmButtonText: i18n.t('i18n.ok'),
                showCancelButton: true,
                cancelButtonText: i18n.t('i18n.cancel'),
                allowOutsideClick: false,
                allowEscapeKey: false,
                inputValidator: function(value) {
                    if (!value) {
                        return i18n.t('i18n.valueIsRequired');
                    }
                }
            };
            $.extend(true, defaults, options || {});
            Swal.fire(defaults).then(function(result) {
                if (result.value) {
                    typeof callback === 'function' && callback(result);
                }
            });
        },
        follow: function(action, $that) {
            if (action.detached.length) {
                $that.addClass('btn-secondary').removeClass('btn-light').text(i18n.t('i18n.followTitle'));
            } else if (action.attached.length) {
                $that.addClass('btn-light').removeClass('btn-secondary').text(i18n.t('i18n.followingTitle'));
            }
        },
        followable: function(url, callback) {
            if ($('.followable-button').length) {
                const $self = this;
                $(document).on('click', '.followable-button', function() {
                    const $this = $(this);

                    var action = {};
                    action['action'] = $this.data('action');
                    action['id'] = $this.data('id');
                    action['url'] = url;

                    if (action['action'] == '' || !url) {
                        $self.sweetError(i18n.t('i18n.somethingWentWrong'));
                        return false;
                    }

                    axios.post(url, action).then(res => {
                        if (res.data.reload) {
                            location.reload();
                        }
                        if (res.data.success) {
                            if (action['action'] == 'follow') {
                                $self.follow(res.data.action, $this);
                            }
                            typeof callback === 'function' && callback(res.data);
                        }
                    }).catch(err => {
                        Quizier.sweetError(err.response.data.message);
                        return false;
                    });

                    return false;
                });
            }
        },
    }
}();

var wow = new WOW({
    boxClass: 'wfit'
});
wow.init();

window.Quizier = Quizier;
$(document).ready(function() {
    Quizier.init();

    $.getScript('https://connect.facebook.net/en_US/sdk.js', function() {
        Quizier.initFB();
    });

    $('body').addClass('quizier-initialized');
});
